<script setup>
  import { Head, Link } from '@inertiajs/vue3';
  import SgGuestNavbar from '@/Components/Navs/SgGuestNavbar.vue';
  import Hero from '@/Components/Hero.vue';
  import IndustryLeaders from '@/Components/IndustryLeaders.vue';
  import AboutSection1 from '@/Components/AboutSection1.vue';
  import WelcomeCardSection from '@/Components/WelcomeCardSection.vue';
  import WelcomeFeaturesSection from '@/Components/WelcomeFeaturesSection.vue';
  import WelcomeSecurity from '@/Components/WelcomeSecurity.vue';
  import WelcomeConnectedSection from '@/Components/WelcomeConnectedSection.vue';
  import NewFooter from '@/Components/NewFooter.vue';
  import WelcomeBlackDiv from '@/Components/WelcomeBlackDiv.vue';
  import FadeInSection from '@/Components/FadeInSection.vue';

  import mainHero from '../../../public/images/welcome/mainHero.jpeg';
  import vector2 from '../../../public/images/welcome/GuyWorkingout.png';
  //   import rectangle2 from '../../../public/images/Rectangle2.png';
  //   import rectangle3 from '../../../public/images/Rectangle3.png';
  import rectangle2 from '../../../public/images/welcome/PunkrockGirlPurple.png';
  import rectangle3 from '../../../public/images/welcome/GirlCamera.png';
  import featureImage1 from '../../../public/images/welcome/womanOnComputer.png';
  //   import featureImage2 from '../../../public/images/features2.png';
  //   import featureImage3 from '../../../public/images/features3.png';

  //   const cardTitle1 = 'Organized Calendar';
  //   const cardTitle2 = 'Gig Oppurtunities';
  //   const cardTitle3 = 'Secure Messaging';
  //   const cardDescription1 =
  //     'Stay on top of your schedule with an integrated calendar designed for creators.';
  //   const cardDescription2 =
  //     'Explore a range of creative gigs, from quick projects to long-term collaborations.';
  //   const cardDescription3 =
  //     'Build strong professional connections with our secure, in-app messaging.';
  //   const title = 'Empower Your Career with';
  //   const subtitle = 'the Right Gigs';
  //   const description =
  // 'Discover opportunities that match your passion and take control of your career path.';
  const featureTitle = 'Key Features For';
  const featureSubtitle = 'Creative Success';
  const featureDescription =
    'Seamless connections with clients and fellow creators.';

  const featurePoint1Title = 'Private and Public Gig Posting';
  const featurePoint2Title = 'Calendar Management';
  const featurePoint3Title = 'Profile Creation and Customization';
  const featurePoint1Description =
    'Flexibility for sharing gigs with individuals or whole community.';
  const featurePoint2Description =
    'Dedicated space for tracking gigs and personal schedules.';
  const featurePoint3Description =
    'Simple profile tools that allow for easy setup and updates.';

  console.log('image vector', vector2);
</script>

<template>
  <div class="min-h-screen bg-white">
    <Head title="Welcome" />
    <div class="justify-center">
      <SgGuestNavbar />
    </div>
    <!-- <img :src="mainHero" alt="" /> -->
    <!-- <Hero /> -->
    <Hero
      :image="mainHero"
      description="Spicy Gigs gives creators a safe, streamlined space to find and manage gigs, collaborate, and stay organized—all for a simple $20/month."
      buttonText1="Join Spicygigs"
      buttonText2="Explore More"
      buttonLink1="/waitlist"
    />
    <!-- <IndustryLeaders /> -->
    <div id="collaborate"></div>
    <FadeInSection>
      <AboutSection1
        class="my-8"
        :imageVector="vector2"
        :imageRectangleTop="rectangle2"
        :imageRectangleBottom="rectangle3"
        title="Connect, Collaborate"
        subtitle="Create"
        description="At spicy gigs, we make it simple for creators, producers, and studios to connect and
    collaborate in one secure, easy-to-use platform. No more juggling multiple social media
    outlets—everything you need, from calendar management to gig bookings, is right here.
    Our intuitive search engine makes it quick and easy to find available creators in your area,
    so you can start collaborating right away."
        buttonText="Get Started"
        buttonLink="/waitlist"
      />
    </FadeInSection>

    <!-- <WelcomeCardSection
      class="mt-8"
      :title="title"
      :subtitle="subtitle"
      :description="description"
      :cardImage1="cardImage1"
      :cardImage2="cardImage2"
      :cardImage3="cardImage3"
      :cardTitle1="cardTitle1"
      :cardTitle2="cardTitle2"
      :cardTitle3="cardTitle3"
      :cardDescription1="cardDescription1"
      :cardDescription2="cardDescription2"
      :cardDescription3="cardDescription3"
    /> -->

    <FadeInSection>
      <WelcomeFeaturesSection
        class="mt-16"
        :featureTitle="featureTitle"
        :featureSubtitle="featureSubtitle"
        :featureDescription="featureDescription"
        :featureImage1="featureImage1"
        :featurePoint1Title="featurePoint1Title"
        :featurePoint2Title="featurePoint2Title"
        :featurePoint3Title="featurePoint3Title"
        :featurePoint1Description="featurePoint1Description"
        :featurePoint2Icon="'fas fa-calendar-alt'"
        :featurePoint2Description="featurePoint2Description"
        :featurePoint3Description="featurePoint3Description"
      />
      <!-- </FadeInSection>
    <FadeInSection> -->
    </FadeInSection>
    <FadeInSection>
      <WelcomeSecurity class="mt-16" />
    </FadeInSection>

    <WelcomeConnectedSection class="mt-8" />
    <WelcomeBlackDiv class="-mt-44" />
    <NewFooter class="" />
  </div>
</template>

<style scoped>
  a:hover {
    color: white !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition:
      opacity 0.5s ease,
      transform 0.5s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }
</style>
